 import React from 'react';
import './value.css';
import { useTranslation } from 'react-i18next';

const Value = () => {
  const { t } = useTranslation();
  return (
    <div className="container_value">
      <h1>{t('Nos Valeurs')}</h1>
      <div className="value-container">
        <div className="value-circle ethic">
          <h2>{t('Ethique')}</h2>
          <p>{t('Nous considérons et nous croyons dans notre approche le partage et l\’implication des valeurs éthiques positives dans notre écosystème. Parmi ces valeurs positives, nous citons entre autres le respect, la responsabilité, l\'intégrité, la confidentialité, la loyauté, et l\'honnêteté.')}</p>
        </div>
        <div className="value-circle quality">
          <h2>{t('Qualité')}</h2>
          <p>{t('L\'adaptation aux marchés exige un alignement continu avec les normes de qualité mondiales concernant les produits, services, processus et fonctionnement. La qualité découle d\'un effort soutenu, rigoureux et constant à toutes les étapes et dans les moindres détails du processus.')}</p>
        </div>
        <div className="value-circle credibility">
          <h2>{t('Crédibilité')}</h2>
          <p>{t('Des informations fiables sont essentielles pour une prise de décision éclairée dans tout développement. Nous priorisons donc la crédibilité des informations et des acteurs de notre écosystème')}</p>
        </div>
      </div>
    </div>
  );
};

export default Value;
