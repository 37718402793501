import React from 'react';
import './contact.css'; // Assurez-vous de créer un fichier CSS pour styliser le formulaire
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className="container_contact">
      <h2 >{t('Contactez-Nous')}</h2>
      <form  className="contact-form" action={process.env.REACT_APP_FORMSPREE_URL} method="POST">
        <div className="form-group">
          <input type="text" id="firstName" name="firstName" placeholder={t('Prénoms')} />
          <input type="text" id="lastName" name="lastName" placeholder={t('Nom')} />
        </div>
        <div className="form-group">
          <input type="email" id="email" name="email" placeholder={t('Email')}/>
          <input type="tel" id="phone" name="phone" placeholder={t('Téléphone')} />
        </div>
        <div className="form-group">
          <textarea id="message" name="message" rows="5" placeholder={t('Votre message')}></textarea>
        </div>
        <div className="form-group">
          <button type="submit">{t('Envoyer')}</button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
