import React from 'react';
import NavBar from './components/navbar/navbar'; 
import { Routes, Route } from 'react-router-dom';
import Navigation from './Navigation';

const App = () => {
  return (
    <React.Fragment>
    <NavBar />
    <Routes>
      <Route path="/" element={<Navigation />} />
      
      {/* Définissez d'autres routes ici si nécessaire */}
    </Routes>
    </React.Fragment>
  );
};

export default App;

