import React from 'react';
import './about.css'; // Importez votre fichier CSS
import { Button } from '@mui/material';
import Image from '../../assets/images/propos-1.webp'; // Importez votre image
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  
  const handleCalendlyClick = () => {
    // Remplacez cet URL par le lien de votre compte Calendly
    window.open('https://calendly.com/contact-hoagtarget', '_blank');
  };
  return (
    <div className="container">
      <div className="about-content">
        <div className="about-text">
          <h1>{t('Qui sommes-nous ?')}</h1>
          <p>{t('Hoag est un cabinet de conseil spécialisé en gestion du changement, avec un accent sur la transformation digitale, l\'intérim, et l\'insertion professionnelle des travailleurs .')}</p>
          <p>{t('HOAG est une galaxie en anneau constituée d’étoiles bleues extrêmement brillantes et relativement jeunes. La science explique l’existence de ces galaxies en anneaux par la collision entre une galaxie jeune et une galaxie plus grande.')}</p>
          <p>{t('Hoag a été choisie par analogie à de jeunes et petits « marchés » comme Madagascar qui disposent d’un potentiel intéressant à tous les niveaux, et qui sont capables de créer des dynamiques exceptionnelles et profitables avec des espaces marchés plus grands, plus expérimentés ou plus exigeants, à condition de penser « autrement » dans leur conception de gestion et de l’exportation.')}</p>
        </div>
        <div className="about-image">
          <img src={Image} alt="Image description" width="500" height="500"/>
        </div>
        <div className="button">
        <Button variant="contained" onClick={handleCalendlyClick} style={{ backgroundColor: '#273A97', color: '#ffffff', textAlign:'center',}}href="https://calendly.com/contact-hoagtarget" target="_blank">
        {t('Contactez-nous pour une consultation personnalisée!')}
        </Button>
      </div>
      </div>
    </div>
  );
};

export default About;
