import React from 'react';
import { Card, CardContent, Typography } from '@mui/material'; // Importez les composants de Material-UI
import './mission.css'; // Importez votre fichier CSS pour les styles personnalisés
import innovationImage from '../../assets/images/innovation (1).png'; // Importez l'image d'innovation
import employabiliteImage from '../../assets/images/employabilite_mondiale.jpg'; // Importez l'image d'innovation
import outilsImage from '../../assets/images/outils_digitaux.png'; // Importez l'image d
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Mission = () => {
  const { t } = useTranslation();
  const handleCalendlyClick = () => {
    // Remplacez cet URL par le lien de votre compte Calendly
    window.open('https://calendly.com/contact-hoagtarget', '_blank');
  };
  return (
    <div className="container_mission">
      <h1>{t('Nos missions')}</h1>
      <div className="card-container">
        <Card className="card">
          <CardContent>
            <img src={innovationImage} alt="Innovation" className="innovation-image" width="100" height="100"/> {/* Ajoutez l'image d'innovation */}
            <Typography variant="body1">
            {t('La culture de l’innovation, la mise en œuvre et la vulgarisation de la transformation digitale à Madagascar.')}
            </Typography>
            <Button variant="contained" onClick={handleCalendlyClick} style={{ backgroundColor: '#E0CDA9', color: '#7F3F98', fontSize:'12px', marginTop:'60px', fontWeight: 'bold' }} >
                {t('Contactez-nous pour une consultation personnalisée!')}
            </Button>
          </CardContent>
        </Card>
        <Card className="card">
          <CardContent>
            <img src={employabiliteImage} alt="Employabilité mondiale" className="employabilite-image" width="100" height="100"/> 
            <Typography variant="body1">
            {t('La mise en place d’un vivier de travailleurs et de talents qualifiés de Madagascar qui seront employables à l’échelle mondiale.')}
            </Typography>
            <Button variant="contained" onClick={handleCalendlyClick} style={{ backgroundColor: '#7F3F98', color: '#E0CDA9', fontSize:'12px', marginTop:'60px', fontWeight: 'bold' }} >
                {t('Soumettez votre demande de personnel intérimaires')}
            </Button>
          </CardContent>
        </Card>
        <Card className="card">
          <CardContent>
            <img src={outilsImage} alt="Outils digitaux" className="outils-image" width="100" height="100"/> 
            <Typography variant="body1">
            {t('La conception et la fourniture des outils digitaux pour faciliter la mise en œuvre des normes de qualité et réduire le gaspillage de ressources, y compris le temps, les finances, les matières premières, etc.')}
            </Typography>
            <Button variant="contained" onClick={handleCalendlyClick} style={{ backgroundColor: '#E0CDA9', color: '#7F3F98', fontSize:'12px', marginTop:'30px', fontWeight: 'bold' }} >
                {t('Contactez-nous pour une consultation personnalisée!')}
            </Button>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Mission;
