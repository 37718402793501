import React from 'react';
import NavBar from './components/navbar/navbar'; // Importez votre composant NavBar
import NavbarMenu from './components/navbarMenu/navbarMenu';
import Accueil from './pages/accueil/accueil';
import About from './pages/about/about';
import Vision from './pages/vision/vision';
import Mission from './pages/mission/mission';
import Activity from './pages/activity/activity';
import Value from './pages/value/value';
import Contact from './pages/contact/contact';
import Footer from './components/footer/footer';
import ScrollToTopButton from './components/scroll/scroll'; // Importez votre composant ScrollToTopButton
import WhatsAppButton from './components/ButtonWhatsapp/buttonWhatsapp'; // Importez votre composant
import CalendlyButton from './components/ButtonCalendly/calendly';
import './App.css';

function Navigation() {
  return (
    <div className="app-container">
    <NavBar />
    <NavbarMenu />
    <div className="content">
      <div id="accueil"><Accueil /></div>
      <div id="about"><About /></div>
      <div id="vision"><Vision /></div>
      <div id="mission"><Mission /></div>
      <div id="activity"><Activity /></div>
      <div id="value"><Value /></div>
      <div id="contact"><Contact /></div>
    </div>
    <Footer />
    <WhatsAppButton />
    <ScrollToTopButton />
    <WhatsAppButton />
    <CalendlyButton />
  </div>
  );
}

export default Navigation;
