import React from 'react';
import Button from '@mui/material/Button';
import './vision.css';
import { useTranslation } from 'react-i18next';

const Vision = () => {
  const { t } = useTranslation();

  const handleCalendlyClick = () => {
    // Remplacez cet URL par le lien de votre compte Calendly
    window.open('https://calendly.com/contact-hoagtarget', '_blank');
  };
  return (
    <div className="container_vision">
      <div className='vision_paragraph'>
        <h1 className='h1_vision' > {t('Notre vision')}</h1>
        <p className='vision_text'>
        {t('Fondé sur les principes d\'innovation et d\'adaptabilité, notre cabinet se dédie à accompagner les entreprises dans leurs défis de transformation digitale et de gestion des ressources humaines.')}
        </p>
        <Button variant="contained" onClick={handleCalendlyClick} style={{ backgroundColor: '#7F3F98',border : '1px solid #ffff', color: '#ffffff' }} href="https://calendly.com/contact-hoagtarget" target="_blank">
        {t('Contactez-nous pour une consultation personnalisée!')}
        </Button>
      </div>
    </div>
  );
};

export default Vision;
