import React, { useRef, useState, useEffect } from 'react';
import './navbarMenu.css';
import Logo from '../../assets/images/logo_hoag.png'; // Importez votre logo
import MenuIcon from '@mui/icons-material/Menu'; // Importez l'icône de menu
import flagUSA from '../../assets/images/flag_usa.webp'; // Importez l'image du drapeau américain
import flagFrance from '../../assets/images/flag_france.webp';
import { useTranslation } from 'react-i18next';

const NavbarMenu = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const navbarRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false); // État pour gérer l'affichage du menu
  const [isSmallScreen, setIsSmallScreen] = useState(false); // État pour vérifier si l'écran est petit
  const [showFlags, setShowFlags] = useState(false); // État pour afficher les drapeaux sur les petits écrans après avoir cliqué sur l'icône de menu

  useEffect(() => {
    // Fonction pour vérifier la largeur de l'écran et mettre à jour l'état
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Vérifie si la largeur de l'écran est inférieure à 768px
    };

    // Écoute les changements de taille de l'écran
    window.addEventListener('resize', checkScreenSize);

    // Vérifie la taille de l'écran lors du chargement initial
    checkScreenSize();

    // Nettoie l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const navigateToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      let offset = navbarRef.current.offsetHeight; // Hauteur de la barre de navigation
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', offset });
      setMenuOpen(false); // Fermez le menu après avoir cliqué sur un élément
      setShowFlags(false); // Masquez les drapeaux après avoir cliqué sur un élément
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Inversez l'état du menu à chaque clic sur l'icône de menu
    setShowFlags(!showFlags); // Affichez les drapeaux après avoir cliqué sur l'icône de menu
  };

  useEffect(() => {
    // Fonction pour gérer le comportement de clic des liens internes
    const handleInternalLinkClick = (event) => {
      // Vérifie si le lien est interne et s'il pointe vers une ancre sur la même page
      if (event.target.tagName === 'A' && event.target.getAttribute('href').startsWith('#')) {
        event.preventDefault(); // Empêche le comportement de clic par défaut

        const sectionId = event.target.getAttribute('href').substring(1); // Récupère l'identifiant de la section
        navigateToSection(sectionId); // Appelle la fonction de navigation vers la section
      }
    };

    // Ajoute un écouteur d'événements pour gérer les clics sur les liens internes
    document.addEventListener('click', handleInternalLinkClick);

    // Nettoie l'écouteur d'événements lors du démontage du composant
    return () => {
      document.removeEventListener('click', handleInternalLinkClick);
    };
  }, []);

  useEffect(() => {
    // Préchargement de l'image du logo
    const logoImage = new Image();
    logoImage.src = Logo;
  }, []);

  return (
    <nav className="navbarMenu" ref={navbarRef}>
       <div className="left">
          <a href="#accueil">
            <img src={Logo} alt="Logo HOAG" className="logo" />
          </a>
        </div>
      <div className="right">
        {isSmallScreen && <MenuIcon className="menu-icon" onClick={toggleMenu} />} {/* Affiche l'icône de menu uniquement pour les petits écrans */}
        <ul className={`menu-list ${menuOpen ? 'active' : ''}`}>
          <li><a href="#accueil">{t('Accueil')}</a></li>
          <li><a href="#about">{t('À Propos')}</a></li>
          <li><a href="#activity">{t('Activités')}</a></li>
          <li><a href="#value">{t('Valeurs')}</a></li>
          <li><a href="#contact">{t('Contact')}</a></li>
          <li><a href="https://hoagtarget.blogspot.com/" target='_blank'>{t('Blog')}</a></li>
          {showFlags && isSmallScreen && (
            <div className="flags">
              <img src={flagUSA} alt="Drapeau américain" className="flag" onClick={() => changeLanguage('en')} />
              <img src={flagFrance} alt="Drapeau français" className="flag" onClick={() => changeLanguage('fr')} />
            </div>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default NavbarMenu;
