import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n'; // Importez votre fichier de configuration i18n
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import flagUSA from '../../assets/images/flag_usa.png'; // Importez l'image du drapeau américain
import flagFrance from '../../assets/images/flag_france.webp';
import './navbar.css';

const Navbar = () => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <nav className="navbar">
      <div className="left">
        <PhoneIcon className="phone-icon" />
        <span className="contact-info">+261 34 70 481 76</span>
        <EmailIcon className="email-icon" />
        <span className="contact-info">contact.hoagtarget@gmail.com</span>
      </div>
      <div className="right">
        <div className="social-icons">
          <a href="https://www.linkedin.com/in/hoag-target-5b0723286/" target="_blank" rel="noopener noreferrer" aria-label="Page LinkedIn de Hoag Target">
            <LinkedInIcon />
          </a>
          <a href="https://www.facebook.com/HoagTarget" target="_blank" rel="noopener noreferrer" aria-label="Page Facebook de Hoag Target">
            <FacebookIcon />
          </a>
        </div>
        <div className="flags">
          <img src={flagUSA} alt="Drapeau américain" className="flag"  title={t('Anglais')} onClick={() => changeLanguage('en')} width="15" height="15" />
          <img src={flagFrance} alt="Drapeau français" className="flag" title={t('Français')} onClick={() => changeLanguage('fr')} width="15" height="15" />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
