import React, { useRef, useEffect } from 'react';
import './footer.css'; // Assurez-vous de créer un fichier CSS pour styliser le Footer
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  const navbarRef = useRef(null);

  useEffect(() => {
    // Assurez-vous que navbarRef.current est mis à jour après le rendu initial
    if (navbarRef.current) {
      const offset = navbarRef.current.offsetHeight; // Hauteur de la barre de navigation
      console.log("Offset Height:", offset);
    }
  }, []); // Le tableau de dépendances vide garantit que cela ne s'exécute qu'une seule fois après le rendu initial

  const navigateToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      let offset = 0;
      if (navbarRef.current) {
        offset = navbarRef.current.offsetHeight; // Hauteur de la barre de navigation
      }
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest', offset });
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container" ref={navbarRef}>
        <div className="footer-section">
          <h3>{t('Contact')}</h3>
          <p>{t('Email')} : contact.hoagtarget@gmail.com</p>
          <p>{t('Téléphone')} : +261 34 70 481 76</p>
          <p>{t('Adresse')} : Lot IVD 89A Behoririka Antananarivo, Madagascar</p>
        </div>
        <div className="footer-section">
          <h3>{t('Newsletter')}</h3> {/* Changement de "Suivez-nous" à "Newsletter" */}
          <form action={process.env.REACT_APP_FORMSPREE_URL} method="POST">
            <input type="email" name="email" placeholder={t('Entrez votre adresse e-mail')} />
            <button type="submit">{t('S\'abonner')}</button>
          </form>
        </div>
        <div className="footer-section">
          <h3>{t('Liens rapides')}</h3>
          <ul>
            <li><a href="#accueil">{t('Accueil')}</a></li>
            <li><a href="#about">{t('À Propos')}</a></li>
            <li><a href="#activity">{t('Activités')}</a></li>
            <li><a href="#value">{t('Valeurs')}</a></li>
            <li><a href="#contact">{t('Contact')}</a></li>
            <li><a href="https://hoagtarget.blogspot.com/" target='_blank'>{t('Blog')}</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 HOAG Target. {t('Tous droits réservés.')}</p>
      </div>
    </footer>
  );
};

export default Footer;
