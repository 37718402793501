import React, { useState } from 'react';
import './accueil.css';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import backgroundImage from '../../assets/images/accueil.webp';

const Accueil = () => {
  const { t } = useTranslation();
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleCalendlyClick = () => {
    // Remplacez cet URL par le lien de votre compte Calendly
    window.open('https://calendly.com/contact-hoagtarget', '_blank');
  };
  return (
    <div className={`background ${imageLoaded ? 'loaded' : ''}`}>
      <img
        src={backgroundImage}
        alt="Background"
        className="background-image"
        onLoad={handleImageLoad}
      />
      <div className="text-container">
        <strong>Hoag Target</strong>
      </div>
      <div className="text" style={{ lineHeight: '2em' }}>
      {t('aboutUs')}{t('abouts')} {t('abouts2')}
      </div>
      <div className="button-container">
        <Button variant="contained" color="primary" onClick={handleCalendlyClick} >
        {t('Contactez-nous pour une consultation gratuite!')}
        </Button>
      </div>
    </div>
  );
};

export default Accueil;
