import React, { useState, useEffect } from 'react';
import './activity.css';
import { Button } from '@mui/material';
import interimImage from '../../assets/images/interim.webp';
import { useTranslation } from 'react-i18next';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Activity = () => {
  const { t } = useTranslation();
  const [activityIndex, setActivityIndex] = useState(1);
  const [isPaused, setIsPaused] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleCalendlyClick = () => {
    // Remplacez cet URL par le lien de votre compte Calendly
    window.open('https://calendly.com/contact-hoagtarget', '_blank');
  };

  const handleNextActivity = () => {
    setActivityIndex(prevIndex => (prevIndex % 3) + 1);
  };

  const togglePause = (e) => {
    // Empêcher la propagation de l'événement de clic vers le conteneur principal
    e.stopPropagation();
    setIsPaused(prevPaused => !prevPaused);
  };

  const toggleClicked = () => {
    setIsClicked(prevClicked => !prevClicked);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        // Change activity index every 15 seconds if not paused
        setActivityIndex((prevIndex) => (prevIndex % 3) + 1);
      }
    }, 15000); // Change activity every 15 seconds
    return () => clearInterval(interval);
  }, [isPaused]);

  const getActivityContent = () => {
    switch (activityIndex) {
      case 1:
        return (
          <div className="activity_item">
            <h2 style={{ textAlign: 'left', color: 'black' }}>
              1/ {t('Transformation Digitale')}
            </h2>
            <p style={{fontSize: '130%'}}>{t('Faites progresser votre entreprise dans l\'ère du numérique avec notre aide experte. Nous vous guidons à travers l\'amélioration de l\'efficacité stratégique et opérationnelle, en intégrant les technologies de pointe, en digitalisant vos processus et en formant vos équipes pour qu\'elles excellent à l\'ère digitale. Transformez vos défis technologiques en véritables opportunités de croissance et d\'expansion.')}</p>
            <Button variant="contained" style={{ backgroundColor: '#cdc18a', color: '#273A97', fontWeight: 'bold' }} onClick={handleCalendlyClick}>
              {t('Contactez-nous pour une consultation gratuite!')}
            </Button>
          </div>
        );
      case 2:
        return (
          <div className="activity_item">
            <h2 style={{ textAlign: 'left', color: 'black' }}>
              2/ {t('Gestion de l\'Intérime')}
            </h2>
            <p style={{fontSize: '130%'}}>{t('Adaptez-vous rapidement aux exigences changeantes du marché du travail avec nos solutions sur mesure pour la gestion de l\'intérim.')} {t('Nous aidons votre entreprise à rester flexible et réactive, en vous fournissant des personnels expérimentés et immédiatement opérationnels, essentiels lors de vos expansions à Madagascar ou à l\'international.')}</p>
            <Button variant="contained" style={{ backgroundColor: '#e3a96b', color: '#273A97', fontWeight: 'bold' }} onClick={handleCalendlyClick}>
              {t('Soumettez votre demande de personnel intérimaires')}
            </Button>
          </div>
        );
      case 3:
        return (
          <div className="activity_item">
            <h2 style={{ textAlign: 'left', color: 'black' }}>
              3/ {t('Insertion et Réinsertion Professionnelle')}
            </h2>
            <p style={{fontSize: '130%'}}>{t('Chez Hoag Target, nous nous engageons à faciliter l\'insertion et la réinsertion professionnelle des travailleurs à Madagascar.')} {t('Nous proposons des programmes adaptés aux nouveaux diplômés souhaitant acquérir des compétences clés, ainsi que des parcours de reconversion pour ceux désirant donner une nouvelle orientation à leur carrière.')}</p>
            <Button variant="contained" style={{ backgroundColor: 'eed464', color: '#273A97', fontWeight: 'bold' }} onClick={handleCalendlyClick}>
              {t('Découvrez nos programmes de reconversion professionnelle')}
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`container_activity ${isPaused ? 'pause' : ''} ${isClicked ? 'box-shadow' : ''}`} onClick={toggleClicked}>
      <img src={interimImage} alt="Interim" className="activity_image" width="400" height="400"/>
      <div className="activity_content">
        <h1>{t('Nos activités')}</h1>
        {getActivityContent()}
      </div>
      <div className={`transition_line ${isPaused ? 'pause' : 'move'}`}></div>
      <div className="buttons-container">
        <Button onClick={togglePause} disableRipple aria-label={isPaused ? 'Reprendre' : 'Mettre en pause'} >
          {isPaused ? <PlayArrowIcon className="icon-large" /> : <PauseIcon className="icon-large" />}
        </Button>
        {/* Bouton "next" */}
        <Button onClick={handleNextActivity} disableRipple aria-label="Suivant">
          <NavigateNextIcon className="icon-large" />
        </Button>
      </div>
    </div>
  );
};

export default Activity;
