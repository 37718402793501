import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importez les fichiers de traduction
import translationEN from '../src/locales/en/translation.json';
import translationFR from '../src/locales/fr/translation.json';

// Configuration de i18next
i18n
  .use(initReactI18next) // Initialiser react-i18next
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      fr: {
        translation: translationFR,
      },
    },
    lng: 'en', // Langue par défaut
    fallbackLng: 'en', // Langue de secours
    interpolation: {
      escapeValue: false, // Pas besoin d'echapper les valeurs
    },
  });

export default i18n;
